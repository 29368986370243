import React, { useState, useEffect } from 'react';
import './table.css';
import ModalComponent from './modal';
import { db } from '../utils/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import AdditionalInnerUser from './additionalInnerUser';

const UserList = ({ users }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserInfo, setSelectedUserInfo] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openModal = (user) => {
        setSelectedUser(user);
    };

    const closeModal = () => {
        setSelectedUser(null);
    };

    const handleInputChange = (e, key) => {
        const newValue = e.target.value;
        setSelectedUser(prevState => ({
            ...prevState,
            [key]: newValue
        }));
    };

    const handleEditUser = async () => {
        try {
            const userDocRef = doc(db, 'userProfile', selectedUser.id);
            await updateDoc(userDocRef, {
                displayName: selectedUser.displayName,
                emailId: selectedUser.emailId,
                phoneNumber: selectedUser.phoneNumber,
                type: selectedUser.type,
                percentage: selectedUser.percentage,
                wallet: selectedUser.wallet,
                masterEmail: selectedUser.masterEmail,
                adminEmail: selectedUser.adminEmail
            });
            console.log("User updated successfully:", selectedUser);
            closeModal(); // Close modal after editing
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const handleUsernameClick = (username, type) => {
        setSelectedUserInfo({ username, type });
    };

    return (
        <div>
            {selectedUser && (
                <ModalComponent closeModal={closeModal}>
                    <div
                        className='addUserModal'
                        style={{
                            flexDirection: 'column',
                            display: "flex",
                            width: windowWidth <= 768 ? '90%' : '90%',
                            maxWidth: '600px',
                            margin: '0 auto',
                            background: 'white',
                            borderRadius: '8px',
                            padding: '20px'
                        }}
                    >
                        <p className='addUserModalHeading'>Edit User</p>
                        <div className="info-Inputs-user">
                            <label htmlFor="name">
                                Name
                                <input
                                    type="text"
                                    id="name"
                                    name="displayName"
                                    placeholder='Enter Name'
                                    value={selectedUser.displayName}
                                    onChange={(e) => handleInputChange(e, 'displayName')}
                                />
                            </label>
                            <label htmlFor="username">
                                User Name
                                <input
                                    type="text"
                                    id="username"
                                    name="emailId"
                                    placeholder='Enter Username'
                                    value={selectedUser.emailId}
                                    onChange={(e) => handleInputChange(e, 'emailId')}
                                />
                            </label>
                            <label htmlFor="adminEmail">
                                Admin Email
                                <input
                                    type="text"
                                    id="adminEmail"
                                    name="adminEmail"
                                    placeholder='Enter Admin Email'
                                    value={selectedUser.adminEmail}
                                    onChange={(e) => handleInputChange(e, 'adminEmail')}
                                />
                            </label>
                            <label htmlFor="masterEmail">
                                Master Email
                                <input
                                    type="text"
                                    id="masterEmail"
                                    name="masterEmail"
                                    placeholder='Enter Master Email'
                                    value={selectedUser.masterEmail}
                                    onChange={(e) => handleInputChange(e, 'masterEmail')}
                                />
                            </label>
                            <label htmlFor="phoneNumber">
                                Phone Number
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder='Enter Phone Number'
                                    value={selectedUser.phoneNumber}
                                    onChange={(e) => handleInputChange(e, 'phoneNumber')}
                                />
                            </label>
                            <label htmlFor="type">
                                Type
                                <select
                                    id="type"
                                    name="type"
                                    value={selectedUser.type}
                                    onChange={(e) => handleInputChange(e, 'type')}
                                >
                                    <option value="User">User</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Master">Master</option>
                                </select>
                            </label>
                            <label htmlFor="percentage">
                                Percentage
                                <input
                                    type="text"
                                    id="percentage"
                                    name="percentage"
                                    placeholder='Enter Percentage'
                                    value={selectedUser.percentage}
                                    onChange={(e) => handleInputChange(e, 'percentage')}
                                />
                            </label>
                            <label htmlFor="balance">
                                Balance
                                <input
                                    type="text"
                                    id="balance"
                                    name="wallet"
                                    placeholder='Enter Balance'
                                    value={selectedUser.wallet}
                                    onChange={(e) => handleInputChange(e, 'wallet')}
                                />
                            </label>
                            <button className='addUserSubmit' onClick={handleEditUser}>Save Changes</button>
                        </div>
                    </div>
                </ModalComponent>
            )}
            <div className="table-wrapper">
                <table className="fl-table" style={{ borderCollapse: 'separate' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Type</th>
                            <th>Phone Number</th>
                            <th>%</th>
                            <th>Balance</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((user, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid #000' }} data-label="Name">{user.displayName}</td>
                                <td style={{ border: '1px solid #000' }} data-label="Username" onClick={() => handleUsernameClick(user.emailId, user.type)}>
                                    {user.emailId}
                                </td>
                                <td style={{ border: '1px solid #000' }} data-label="Type">{user.type}</td>
                                <td style={{ border: '1px solid #000' }} data-label="Phone Number">{user.phoneNumber}</td>
                                <td style={{ border: '1px solid #000' }} data-label="%">{user.percentage}</td>
                                <td style={{ border: '1px solid #000' }} data-label="Balance">{user.wallet}</td>
                                <td style={{ border: '1px solid #000' }} data-label="Action">
                                    <button className='viewEditUser' onClick={() => openModal(user)}>
                                        View/Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedUserInfo && <AdditionalInnerUser username={selectedUserInfo.username} type={selectedUserInfo.type} />}
        </div>
    );
};

export default UserList;
