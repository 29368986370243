import React, { useEffect, useState } from 'react';
import { auth, db } from './utils/firebase';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, getDocs } from 'firebase/firestore';
import { where } from 'firebase/firestore';
import Loading from './Components/loading';

const ProtectedRoute = () => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userReq, setuserReq] = useState(null); // Initialize as null
    const navigate = useNavigate();

    const fetchData = async (userEmail) => {
        try {
            console.log(userEmail);
            const q = query(collection(db, "userProfile"), where("emailId", "==", userEmail));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {


                const dataWithId = { ...doc.data(), id: doc.id };
                setuserReq(dataWithId);
                console.log(doc.data().type);
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    };

    useEffect(() => {
        console.log(auth.currentUser);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log(auth.currentUser);
                console.log("User True");
                setAuthenticated(true);
                fetchData(user.email); // Fetch data for the authenticated user
            } else {
                setAuthenticated(false);
                setLoading(false); // Set loading to false if not authenticated
                console.log('Not Logged In');
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    // Redirect logic after fetching data
    useEffect(() => {
        if (loading) return;

        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (userReq) {
            if (userReq.type === "Super Admin") {
                navigate('/super-admin/');
            } else if (userReq.type === "Admin") {
                navigate('/admin/');
            } else if (userReq.type === "Master") {
                navigate('/master/');
            } else if (userReq.type === "User") {
                navigate('/admin/');
            } else {
                navigate('/login'); // Redirect if user type is not recognized
            }
        }
    }, [loading, isAuthenticated, userReq]);

    if (loading) {
        return <Loading />;
    }

    // Placeholder while waiting for authentication check
    if (!isAuthenticated) {
        return null;
    }

    // Placeholder while waiting for data
    if (!userReq) {
        return <div>Loading user data...</div>;
    }

    // This should not be reached if redirects are successful
    return <div><Outlet /></div>;
};

export default ProtectedRoute;
