import React, { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import './navbar.css'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../utils/firebase'
import Home from '../Routes/home'


const NavbarMaster = () => {
  const [isAuth, setIsAuth] = useState(false)
  const [user, setUser] = useState()

  useEffect(() =>
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuth(true)
        setUser(user)
        console.log(user);
      } else {
        setIsAuth(false)
      }
    }))
  return (
    <div>
      <header>
        <div className="logo-section">Fatafat Daily</div>
        <div className="next-sec-logo">
          {isAuth ? <button className='userNameDisplay'>{user.displayName}</button> : <div className='divvStyleLR'>
            <Link to="/login"><button className='loginDisplay'>Login</button></Link>
            <Link to="/register"><button className='signupDisplay'>Register</button></Link>
          </div>}
        </div>
      </header>
      <div className="links-tabs">
        <ul className='scrollable-ul'>
          <li className="liinks-tab-child"><Link to='/'>Home</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/master/game">Game</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/master/users">Users</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/market-analysis">Market Analysis</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/master/profit-loss">Profit / Loss</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/master/fund-req">Fund</Link></li>
          <li className="border-only--li"></li>

          <li className="liinks-tab-child"><Link to='/master/bet-history'>Bet Log</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to='/master/withdraw-request'>Withdraw Request</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to='/master/withdraw-history'>Withdraw History</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to='/master/fund-add-history'>Fund Add History</Link></li>
          <li className="border-only--li"></li>
          {/* <li className="liinks-tab-child"><Link to='/user-log'>User Log</Link></li> */}
          {/* <li className="border-only--li"></li> */}
          {/* <li className="liinks-tab-child"><Link>Password</Link></li> */}
          {/* <li className="border-only--li"></li> */}
          <li className="liinks-tab-child"><div onClick={() => {
            signOut(auth).then(() => {
            }).catch((error) => {
            });
          }}>Logout</div></li>
        </ul>
      </div>
      <Outlet />
    </div >
  )
}

export default NavbarMaster
