import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const FundReq = () => {
    const [fundRequests, setFundRequests] = useState([]);
    const [selectedActions, setSelectedActions] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "fund-request"));
                const requests = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setFundRequests(requests);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSelectChange = (id, action) => {
        setSelectedActions(prevState => ({ ...prevState, [id]: action }));
    };

    const handleConfirmAction = async (id) => {
        const action = selectedActions[id];
        if (!action) {
            console.error('No action selected');
            return;
        }

        try {
            const fundRequest = fundRequests.find(request => request.id === id);
            const fundRef = doc(db, "fund-request", id);
            const { emailId, amount } = fundRequest;

            const userProfileQuerySnapshot = await getDocs(collection(db, "userProfile"));
            const userProfileDoc = userProfileQuerySnapshot.docs.find(doc => doc.data().emailId === emailId);

            if (!userProfileDoc) {
                console.error('User profile not found');
                return;
            }

            const userProfileRef = doc(db, "userProfile", userProfileDoc.id);
            const userProfileData = userProfileDoc.data();

            if (action === "Approve") {
                const walletBefore = parseFloat(userProfileData.wallet) || 0;
                const walletAfter = walletBefore + parseFloat(amount);

                const fundAddHistoryEntry = {
                    walletBefore,
                    walletAfter,
                    amount: parseFloat(amount),
                    date: new Date().toLocaleDateString(),
                    time: new Date().toLocaleTimeString(),
                    status: "approved"
                };

                await updateDoc(userProfileRef, {
                    wallet: walletAfter,
                    fundAddHistory: [...userProfileData.fundAddHistory || [], fundAddHistoryEntry]
                });

                await updateDoc(fundRef, { status: "approved" });
            } else if (action === "Decline") {
                const walletBefore = parseFloat(userProfileData.wallet) || 0;
                const walletAfter = walletBefore;

                const fundWithdrawHistoryEntry = {
                    walletBefore,
                    walletAfter,
                    amount: 0,
                    date: new Date().toLocaleDateString(),
                    time: new Date().toLocaleTimeString(),
                    status: "declined"
                };

                await updateDoc(userProfileRef, {
                    fundAddHistory: [...userProfileData.fundWithdrawHistory || [], fundWithdrawHistoryEntry]
                });

                await updateDoc(fundRef, { status: "declined" });
            }

            await deleteDoc(fundRef);

            const updatedFundRequests = fundRequests.filter(request => request.id !== id);
            setFundRequests(updatedFundRequests);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    return (
        <div>
            <div className="table-wrapper">
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th>Fund ID</th>
                            <th>TXN ID</th>
                            <th>Amount</th>
                            <th>Date & Time</th>
                            <th>Email ID</th>
                            <th>UID</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fundRequests.map((request) => (
                            <tr key={request.id}>
                                <td>{request.fundId}</td>
                                <td>{request.txnId}</td>
                                <td>{request.amount}</td>
                                <td>{request.date} || {request.time}</td>
                                <td>{request.emailId}</td>
                                <td>{request.uid}</td>
                                <td className='tdFunReq-ButtonAlign'>
                                    <select onChange={(e) => handleSelectChange(request.id, e.target.value)}>
                                        <option value="">Select Action</option>
                                        <option value="Approve">Approve</option>
                                        <option value="Decline">Decline</option>
                                    </select>
                                    <button onClick={() => handleConfirmAction(request.id)}>Confirm</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FundReq;
