import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const BetHistory = () => {
    const [betHistory, setBetHistory] = useState([]);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // Initial sorting order
    const [emailFilter, setEmailFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');

    useEffect(() => {
        const today = new Date();
        const formattedToday = formatDate(today);
        setDateFilter(formattedToday);
        console.log('Today’s date:', formattedToday);
    }, []);

    useEffect(() => {
        fetchBetHistory();
    }, [dateFilter]);

    const fetchBetHistory = async () => {
        try {
            let q = query(collection(db, 'bets'));

            // Apply email filter if entered
            if (emailFilter) {
                q = query(q, where('user', '==', emailFilter));
            }

            // Apply date filter if entered
            if (dateFilter) {
                // Query Firestore with the formatted date
                q = query(q, where('date', '==', dateFilter));
            }

            const querySnapshot = await getDocs(q);
            const betHistoryData = [];

            querySnapshot.forEach(doc => {
                const betData = doc.data();
                betHistoryData.push({
                    id: doc.id,
                    date: betData.date,
                    time: betData.time,
                    number: betData.number,
                    amount: betData.amount,
                    email: betData.user,
                    uid: betData.uid,
                    gameName: betData.gameName
                });
            });

            setBetHistory(betHistoryData);
        } catch (error) {
            console.error('Error fetching bet history:', error);
        }
    };

    // Function to format the date to dd/mm/yyyy
    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleCancelBet = async (betId, email, amount) => {
        const formatDateCancel = (date) => {
            const [day, month, year] = date.split('/');
            return `${day}-${month}-${year}`;
        };

        console.log(`Canceling bet with ID: ${betId}`);

        let gamedate;
        let gameid;
        let declaredBool;

        try {
            const docRef = doc(db, 'bets', betId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log('Bet document data:', docSnap.data());
                gamedate = formatDateCancel(docSnap.data().date);
                gameid = docSnap.data().gameID;
                console.log(gamedate, gameid);
            } else {
                console.log(`Bet ID ${betId} - No such document!`);
                return;
            }
        } catch (error) {
            console.error('Error fetching bet document:', error);
            return;
        }

        try {
            const declareData = doc(db, gamedate, gameid);
            const declareSnap = await getDoc(declareData);

            if (declareSnap.exists()) {
                console.log('Declare document data:', declareSnap.data());
                declaredBool = declareSnap.data().declared;
            } else {
                console.log(`Declare document for ${gamedate} / ${gameid} - No such document!`);
                return;
            }
        } catch (error) {
            console.error('Declare Check Failed due to:', error);
            alert("Declaration Check Failed - Can't Cancel Bet")
            return;
        }

        if (declaredBool) {
            alert(`Bet cannot be canceled because it is already declared.`);
            return;
        }

        try {
            const userProfileQuery = query(collection(db, 'userProfile'), where('emailId', '==', email));
            const userProfileSnapshot = await getDocs(userProfileQuery);

            userProfileSnapshot.forEach(async (userProfileDoc) => {
                const userProfileData = userProfileDoc.data();
                console.log('User profile data:', userProfileData);
                const newWalletAmount = parseFloat(userProfileData.wallet) + parseFloat(amount);

                const userProfileRef = doc(db, 'userProfile', userProfileDoc.id);
                await updateDoc(userProfileRef, {
                    wallet: newWalletAmount
                });
            });

            await deleteDoc(doc(db, 'bets', betId));
            console.log(`Bet with ID ${betId} has been successfully deleted.`);

            setBetHistory(prevBetHistory => prevBetHistory.filter(bet => bet.id !== betId));
        } catch (error) {
            console.error('Error canceling bet:', error);
        }
    };


    const handleSort = (sortByField) => {
        const newSortOrder = sortBy === sortByField && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(sortByField);
        setSortOrder(newSortOrder);

        const sortedBetHistory = [...betHistory].sort((a, b) => {
            if (sortByField === 'email') {
                return newSortOrder === 'asc' ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
            } else if (sortByField === 'date') {
                return newSortOrder === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
            }
            return 0;
        });

        setBetHistory(sortedBetHistory);
    };

    return (
        <div>
            <div className="inputSort-bethistory">
                <input
                    type="text"
                    placeholder="Enter Email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                />
                <button onClick={fetchBetHistory}>Search</button>
                <input
                    type="date"
                    placeholder="Select Date"
                    value={dateFilter.split('/').reverse().join('-')}
                    onChange={(e) => setDateFilter(formatDate(e.target.value))}
                />
            </div>

            <div className="table-wrapper">
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('date')}>Date</th>
                            <th>Time</th>
                            <th>Number</th>
                            <th>Amount</th>
                            <th onClick={() => handleSort('email')}>Email ID</th>
                            <th>Game Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {betHistory.map(bet => (
                            <tr key={bet.id}>
                                <td>{bet.date}</td>
                                <td>{bet.time}</td>
                                <td>{bet.number}</td>
                                <td>{bet.amount}</td>
                                <td>{bet.email}</td>
                                <td>Bazi {bet.gameName}</td>
                                <td>
                                    <button onClick={() => handleCancelBet(bet.id, bet.email, bet.amount)}>Cancel Bet</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BetHistory;
