import React, { useEffect, useState } from "react";
import "./route.css";
import { CiClock2 } from "react-icons/ci";
import { TfiCup } from "react-icons/tfi";
import ModalComponent from "../Components/modal";
import { db } from "../utils/firebase";
import { where, query } from "firebase/firestore";
import { addDoc, collection, getDocs, doc, getDoc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";

const Game = () => {


  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear();
  const dateString = `${day}-${month}-${year}`;

  const [modalStatus, setModalStatus] = useState(false)
  const [modalResult, setModalResult] = useState(false)
  const [indexValue, setIndexValue] = useState()
  const [gameState, setGameState] = useState()
  const [gameID, setGameID] = useState()
  const [selectedStatus, setSelectedStatus] = useState("")
  const [selectedNumber, setSelectedNumber] = useState("");
  const [singleResult, setSingleResult] = useState("");
  const [selectedDate, setSelectedDate] = useState(dateString);
  const [currentResult, setCurrentResult] = useState()
  const [bets, setBets] = useState([])
  const [currentTResult, setCurrentTResult] = useState()
  const [combinedData, setCombinedData] = useState([]);


  const closeFunction = (modal) => {
    modal(false)
  }
  const openFunction = (modal, key) => {
    modal(true)
    setIndexValue(key)
    console.log(key);
  }

  const number1to0 = [
    { number: 0 }
    ,
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 },

  ]

  const pattiList = [
    { number: '000' },
    { number: '100' },
    { number: '200' },
    { number: '300' },
    { number: '400' },
    { number: '500' },
    { number: '600' },
    { number: '700' },
    { number: '800' },
    { number: '900' },
    { number: '127' },
    { number: '678' },
    { number: '345' },
    { number: '120' },
    { number: '789' },
    { number: '456' },
    { number: '123' },
    { number: '890' },
    { number: '567' },
    { number: '234' },
    { number: '190' },
    { number: '777' },
    { number: '444' },
    { number: '111' },
    { number: '888' },
    { number: '555' },
    { number: '222' },
    { number: '999' },
    { number: '666' },
    { number: '333' },
    { number: '280' },
    { number: '560' },
    { number: '570' },
    { number: '580' },
    { number: '590' },
    { number: '140' },
    { number: '150' },
    { number: '160' },
    { number: '170' },
    { number: '180' },
    { number: '370' },
    { number: '470' },
    { number: '480' },
    { number: '490' },
    { number: '130' },
    { number: '230' },
    { number: '330' },
    { number: '340' },
    { number: '350' },
    { number: '360' },
    { number: '460' },
    { number: '380' },
    { number: '390' },
    { number: '670' },
    { number: '680' },
    { number: '690' },
    { number: '240' },
    { number: '250' },
    { number: '260' },
    { number: '270' },
    { number: '550' },
    { number: '290' },
    { number: '660' },
    { number: '238' },
    { number: '248' },
    { number: '258' },
    { number: '268' },
    { number: '278' },
    { number: '288' },
    { number: '450' },
    { number: '235' },
    { number: '119' },
    { number: '129' },
    { number: '139' },
    { number: '149' },
    { number: '159' },
    { number: '169' },
    { number: '179' },
    { number: '189' },
    { number: '199' },
    { number: '118' },
    { number: '137' },
    { number: '237' },
    { number: '337' },
    { number: '347' },
    { number: '357' },
    { number: '367' },
    { number: '377' },
    { number: '116' },
    { number: '117' },
    { number: '578' },
    { number: '236' },
    { number: '336' },
    { number: '157' },
    { number: '158' },
    { number: '799' },
    { number: '448' },
    { number: '467' },
    { number: '233' },
    { number: '469' },
    { number: '145' },
    { number: '146' },
    { number: '246' },
    { number: '346' },
    { number: '446' },
    { number: '267' },
    { number: '899' },
    { number: '115' },
    { number: '459' },
    { number: '126' },
    { number: '479' },
    { number: '669' },
    { number: '679' },
    { number: '689' },
    { number: '699' },
    { number: '780' },
    { number: '178' },
    { number: '124' },
    { number: '125' },
    { number: '667' },
    { number: '668' },
    { number: '579' },
    { number: '255' },
    { number: '355' },
    { number: '455' },
    { number: '447' },
    { number: '790' },
    { number: '223' },
    { number: '224' },
    { number: '478' },
    { number: '299' },
    { number: '399' },
    { number: '147' },
    { number: '247' },
    { number: '266' },
    { number: '366' },
    { number: '466' },
    { number: '566' },
    { number: '477' },
    { number: '135' },
    { number: '334' },
    { number: '588' },
    { number: '228' },
    { number: '256' },
    { number: '112' },
    { number: '113' },
    { number: '358' },
    { number: '557' },
    { number: '990' },
    { number: '225' },
    { number: '488' },
    { number: '489' },
    { number: '499' },
    { number: '166' },
    { number: '356' },
    { number: '122' },
    { number: '880' },
    { number: '368' },
    { number: '134' },
    { number: '144' },
    { number: '389' },
    { number: '245' },
    { number: '688' },
    { number: '599' },
    { number: '239' },
    { number: '177' },
    { number: '114' },
    { number: '359' },
    { number: '558' },
    { number: '379' },
    { number: '226' },
    { number: '155' },
    { number: '778' },
    { number: '148' },
    { number: '338' },
    { number: '249' },
    { number: '556' },
    { number: '449' },
    { number: '369' },
    { number: '559' },
    { number: '569' },
    { number: '227' },
    { number: '138' },
    { number: '788' },
    { number: '257' },
    { number: '339' },
    { number: '259' },
    { number: '269' },
    { number: '378' },
    { number: '289' },
    { number: '677' },
    { number: '344' },
    { number: '156' },
    { number: '445' },
    { number: '220' },
    { number: '889' },
    { number: '349' },
    { number: '133' },
    { number: '440' },
    { number: '388' },
    { number: '136' },
    { number: '335' },
    { number: '110' },
    { number: '229' },
    { number: '770' },
    { number: '348' },
    { number: '457' },
    { number: '188' },
    { number: '279' },
    { number: '577' },
    { number: '244' },
    { number: '128' },
    { number: '589' },
    { number: '779' },
    { number: '167' },
    { number: '168' },
    { number: '277' },
    { number: '458' },
    { number: '468' },
    { number: '568' },
  ]


  const game = [
    { gameName: '1', result: null, singleResult: null, resultTime: '10:30', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '7:30', gameTimeEnd: '9:55', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },
    { gameName: '2', result: null, singleResult: null, resultTime: '12:00', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '9:55', gameTimeEnd: '11:25', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },
    { gameName: '3', result: null, singleResult: null, resultTime: '13:30', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '11:25', gameTimeEnd: '12:55', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },
    { gameName: '4', result: null, singleResult: null, resultTime: '15:00', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '12:55', gameTimeEnd: '14:25', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },
    { gameName: '5', result: null, singleResult: null, resultTime: '16:30', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '14:25', gameTimeEnd: '15:55', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },
    { gameName: '6', result: null, singleResult: null, resultTime: '18:00', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '15:55', gameTimeEnd: '17:25', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },
    { gameName: '7', result: null, singleResult: null, resultTime: '19:30', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '17:25', gameTimeEnd: '18:55', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },
    { gameName: '8', result: null, singleResult: null, resultTime: '21:00', declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '18:55', gameTimeEnd: '20:25', gameStatus: 'Upcoming', gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' },]


  useEffect(() => {
    const fetchData = async () => {
      const dateString = selectedDate;

      const querySnapshot = await getDocs(collection(db, dateString));
      const allData = [];
      querySnapshot.forEach((doc) => {
        allData.push({ id: doc.id, ...doc.data() });
      });

      const validData = allData.filter(item => item.gameName);
      validData.sort((a, b) => a.gameName.localeCompare(b.gameName));

      setGameState(validData);
    };
    fetchData();
  }, [selectedDate]);

  const handleSearch = () => {
    const selectedDateObject = new Date(selectedDate); // Convert selectedDate string to Date object
    const day = selectedDateObject.getDate().toString().padStart(2, '0'); // Get day
    const month = (selectedDateObject.getMonth() + 1).toString().padStart(2, '0'); // Get month
    const year = selectedDateObject.getFullYear(); // Get year
    const selectedDateString = `${day}-${month}-${year}`; // Construct the date string in "dd-mm-yyyy" format
    setSelectedDate(selectedDateString); // Update selectedDate state
  };



  const generateGame = async () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month starts from 0
    const year = currentDate.getFullYear();

    // Get the number of days in the current month
    const daysInMonth = new Date(year, month, 0).getDate();

    // Loop through each day from the current date until the end of the month
    for (let i = day; i <= daysInMonth; i++) {
      const dayString = i.toString().padStart(2, '0');
      const monthString = month.toString().padStart(2, '0');
      const dateString = `${dayString}-${monthString}-${year}`;

      const docSnap = await getDocs(collection(db, dateString));

      if (docSnap.empty) {
        // Collection contains documents
        docSnap.forEach((doc) => {
          console.log("Document data:", doc.data());
        });
        console.log(`Document Not Found for ${dateString}`);
        // Loop through each game object and add it as a document to Firestore
        for (const singleGame of game) {
          const docRef = await addDoc(collection(db, dateString), singleGame);
          console.log(`Document written with ID: ${docRef.id} for date: ${dateString}`);
        }
      } else {
        // Collection is empty
        console.log(`Document Found for ${dateString}`);
      }
    }
  };

  const changeStatus = async (setModalResult, key, id) => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    const year = currentDate.getFullYear();
    const dateString = `${day}-${month}-${year}`;

    await openFunction(setModalResult, key)
    await setGameID(id);
    await console.log(gameID);

    const docRef = doc(db, dateString, id);
    const docSnap = await getDoc(docRef);



    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());

    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }

  }


  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // Update the selected status when an option is selected
    console.log(e.target.value);
  };

  const handleStatusSubmit = async () => {
    if (selectedStatus === "") {
      console.log("Please select a status");
      return;
    }

    if (!gameID) {
      console.log("Game ID is not defined");
      return;
    }

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    const year = currentDate.getFullYear();
    const dateString = `${day}-${month}-${year}`;

    const docRef = doc(db, dateString, gameID);
    try {
      await updateDoc(docRef, {
        gameStatus: selectedStatus
      });
      console.log(`Game status updated to ${selectedStatus}`);
      setModalStatus(false);
    } catch (error) {
      console.error("Error updating game status:", error);
    }
    setModalStatus(false)
  };
  const deleteBazi = async (id) => {
    try {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      const dateString = `${day}-${month}-${year}`;

      await deleteDoc(doc(db, dateString, id));
      console.log(`Document with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };
  const getSingleResult = (number) => {
    const sum = number.split('').reduce((acc, digit) => acc + parseInt(digit), 0);
    return sum % 10;
  };

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
    const result = getSingleResult(number);
    setSingleResult(result);
    handleStatusSubmit(number, result);
  };

  const handleResultChange = async () => {
    const confirmation = window.confirm("Are you sure you want to update the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }

    try {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      const dateString = `${day}-${month}-${year}`;

      const docRef = doc(db, dateString, gameID);
      await updateDoc(docRef, {
        result: selectedNumber,
        singleResult: singleResult,
        declared: true
      });
      setSelectedNumber("");
      setSingleResult("");
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const fetchBet = async (gameId) => {
    if (!gameId) return;
    try {
      const q = query(collection(db, "bets"), where("gameID", "==", gameId));
      const querySnapshot = await getDocs(q);
      const allData = [];
      querySnapshot.forEach((doc) => {
        allData.push({ id: doc.id, ...doc.data() });
      });

      const updatedCombinedData = [];
      allData.forEach((item) => {
        const existingItemIndex = updatedCombinedData.findIndex(data => data.number === item.number);
        if (existingItemIndex === -1) {
          updatedCombinedData.push({ ...item });
        } else {
          updatedCombinedData[existingItemIndex].amount += parseFloat(item.amount);
        }
      });

      setCombinedData(updatedCombinedData);
    } catch (error) {
      console.error("Error fetching bets:", error);
    }
  };
  const declareSingleResult = async () => {
    // Confirm before executing the function
    const confirmation = window.confirm("Are you sure you want to declare the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }

    console.log('Passed initial check');

    try {
      console.log('Fetching bets data');

      const q = query(collection(db, "bets"), where("gameID", "==", gameID));
      const allDataWinning = [];
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.data().number === currentResult) {
          allDataWinning.push({ id: doc.id, ...doc.data() });
        }
      });

      console.log('Winning data:', allDataWinning);

      for (const elem of allDataWinning) {
        console.log('Winning Bet ID:', elem.id);

        const refWalletQuery = query(collection(db, "userProfile"), where("uid", "==", elem.uid));
        const walletSnap = await getDocs(refWalletQuery);

        for (const userDoc of walletSnap.docs) {
          const userProfileData = userDoc.data();
          const refWalletUpdate = doc(db, "userProfile", userDoc.id);

          console.log("User profile data:", userProfileData);

          // Update wallet amount and win history
          const amountMultiplied = elem.amount * 9;
          const updatedWalletAmount = parseFloat(userProfileData.wallet) + amountMultiplied;
          const winHistory = userProfileData.winHistory || [];
          const updatedWinHistory = [...winHistory, {
            ...elem,
            status: "win",
            betID: elem.id,
            timestamp: new Date().toISOString()
          }];

          await updateDoc(refWalletUpdate, {
            wallet: updatedWalletAmount,
            winHistory: updatedWinHistory
          });

          console.log(`Updated wallet amount and win history for user ${elem.uid}`);

          // Update betLog status to "win" for the winning bet
          const updatedBetLog = userProfileData.betLog.map(bet => {
            if (bet.betID === elem.id) {
              console.log("Updating Win");
              return { ...bet, status: "win" };
            } else {
              console.log("No update for bet with betID:", bet.betID);
            }
            return bet;
          });

          await updateDoc(refWalletUpdate, {
            betLog: updatedBetLog
          });

          console.log(`Updated betLog status to "win" for bet ${elem.id}`);

          // Update the status field in the bets collection
          const betRef = doc(db, "bets", elem.id);
          const betDoc = await getDoc(betRef);

          if (betDoc.exists()) {
            await updateDoc(betRef, {
              status: "win"
            });
            console.log(`Updated status to "win" for bet ${elem.id}`);
          } else {
            await setDoc(betRef, {
              status: "win"
            }, { merge: true });
            console.log(`Added status "win" for bet ${elem.id}`);
          }
        }
      }

      // Show success message after execution
      alert("Successfully Executed");
    } catch (error) {
      console.error('Error fetching bets data:', error);
    }
  };

  const declareTripleResult = async () => {
    // Confirm before executing the function
    const confirmation = window.confirm("Are you sure you want to declare the result?");
    if (!confirmation) {
      return; // Exit the function if the user cancels
    }

    // Check if the selected number matches the current result number
    if (currentTResult !== selectedNumber) {
      alert('Check the Selected Number and Result Number');
      return;
    }

    console.log('Passed initial check');

    try {
      console.log('Fetching bets data');

      const q = query(collection(db, "bets"), where("gameID", "==", gameID));
      const allDataWinning = [];
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.data().number === currentTResult) {
          allDataWinning.push({ id: doc.id, ...doc.data() });
          console.log(doc.data());
        }
      });

      console.log('Winning data:', allDataWinning);

      for (const elem of allDataWinning) {
        console.log('Winning Bet ID:', elem.id);
        const amountMultiplied = elem.amount * 100;
        console.log('Current Wallet Amount ' + elem.amount)
        console.log(elem);
        const refWalletQuery = query(collection(db, "userProfile"), where("uid", "==", elem.uid));
        const walletSnap = await getDocs(refWalletQuery);

        for (const userDoc of walletSnap.docs) {
          const userProfileData = userDoc.data();
          const refWalletUpdate = doc(db, "userProfile", userDoc.id);

          // Check if the bet's number matches the selected number or currentTResult
          if (elem.number !== selectedNumber && elem.number !== currentTResult) {
            console.log(`Bet number ${elem.number} does not match the selected number or current result number.`);
            continue;
          }

          console.log("User profile data:", userProfileData);

          // Update wallet amount
          const updatedWalletAmount = parseFloat(userProfileData.wallet) + amountMultiplied;
          await updateDoc(refWalletUpdate, {
            wallet: updatedWalletAmount
          });
          console.log(`Updated wallet amount for user ${elem.uid}: ${updatedWalletAmount}`);

          // Update win history
          const winHistory = userProfileData.winHistory || [];
          const updatedWinHistory = [...winHistory, {
            ...elem,
            status: "win",
            betID: elem.id, // Use betID instead of timestamp
            betTime: elem.time // Optionally include the bet time
          }];
          await updateDoc(refWalletUpdate, {
            winHistory: updatedWinHistory
          });
          console.log(`Updated win history for user ${elem.uid}:`, updatedWinHistory);

          // Update betLog status to "win" for the winning bet
          const updatedBetLog = userProfileData.betLog.map((bet) => {
            if (bet.betID === elem.id) {
              console.log("Updating Win");
              return { ...bet, status: "win" };
            } else {
              console.log("No update for bet with betID:", bet.betID);
            }
            return bet;
          });
          await updateDoc(refWalletUpdate, {
            betLog: updatedBetLog
          });
          console.log(`Updated betLog status to "win" for bet ${elem.id}`);

          // Update the status field in the bets collection
          const betRef = doc(db, "bets", elem.id);
          const betDoc = await getDoc(betRef);

          if (betDoc.exists()) {
            await updateDoc(betRef, {
              status: "win"
            });
            console.log(`Updated status to "win" for bet ${elem.id}`);
          } else {
            await setDoc(betRef, {
              status: "win"
            }, { merge: true });
            console.log(`Added status "win" for bet ${elem.id}`);
          }
        }
      }

      // Show success message after execution
      alert("Successfully Executed");
    } catch (error) {
      console.error('Error fetching bets data:', error);
    }
  };






  return (
    <div className="game--wrapper">
      {modalStatus ? <ModalComponent closeModal={() => closeFunction(setModalStatus)} >
        <div className="changeStatus">
          <p className="gameName--modalStatus">
            Game : {game[indexValue].gameName}
          </p>
          <div className="gameDets--status-modal">
            <p> Current Status : {game[indexValue].gameStatus}</p>
            <p>Game Timing : {game[indexValue].gameTimeStart} to {game[indexValue].gameTimeEnd}</p>
          </div>
          <div className="status-ActionModal">
            <p>Status :</p>
            <select name="" id="selectModalStatus" onChange={handleStatusChange}>
              <option value="" >Select</option>
              <option value="Completed">Completed</option>
              <option value="Processing">Processing</option>
              0              <option value="Upcoming">Upcoming</option>
            </select>

          </div>
          <div className="actionToSubmit">
            <button className="submitActionModalStatus" onClick={handleStatusSubmit}>Submit</button>
          </div>
        </div>
      </ModalComponent> : null
      }
      {
        modalResult ? <ModalComponent longHeight={true} closeModal={() => closeFunction(setModalResult)}>
          <div className="modalResultWrapper">
            <div className="modalWrapper-Heading">
              Declare Result
            </div>
            <div className="gameResult-Details">
              <p>Declared Result : {currentResult} | {currentTResult}</p>
            </div>
            <div className="resultWrap">
              <div className="singleResultWrap">
                {
                  number1to0.map((item) => {
                    const combinedInfo = combinedData.find(data => data.number === item.number);
                    const amount = combinedInfo ? combinedInfo.amount : 0;
                    return (
                      <div className="tooltip" key={item.number}>
                        <button className={`btnResult-ModalDeclare ${item.number === currentResult ? 'redBackground' : ''}`}>
                          {item.number}
                          <div className="amount-btnResult-ModalDeclare">
                            {amount}
                          </div>
                        </button>
                        {combinedInfo && (
                          <span className="tooltiptext">
                            Total Amount: {combinedInfo.amount}
                          </span>
                        )}
                      </div>
                    );
                  })
                }
              </div>
              <div className="resultDiv-grid">
                {
                  pattiList?.map((item) => {
                    const combinedInfo = combinedData.find(data => data.number === item.number);
                    const amount = combinedInfo ? combinedInfo.amount : 0;

                    return (
                      <div className="tooltip">
                        <button
                          className={`btnResult-ModalDeclare ${item.number === currentTResult ? 'redBackground' : ''} ${item.number === selectedNumber ? 'greenBackground' : ''} ${combinedInfo ? 'yellowBackground' : ''}`}
                          onClick={() => handleNumberClick(item.number)}
                        >
                          {item.number}
                          <div className="amount-btnResult-ModalDeclare">
                            {amount}
                          </div>
                        </button>
                        {combinedInfo && (
                          <span className="tooltiptext">
                            Total Amount: {combinedInfo.amount}
                          </span>
                        )}
                      </div>
                    );
                  })
                }

              </div>

              {selectedNumber && (
                <div className="result-display">
                  <p>
                    Selected Number: <span>
                      {selectedNumber}
                    </span>

                  </p>
                  <p>
                    Single Result: <span>
                      {singleResult}
                    </span>
                  </p>
                  <button onClick={handleResultChange}>Confirm</button>
                  <button style={{ backgroundColor: 'red' }} onClick={declareSingleResult}>Declare Single Winning</button>
                  <button style={{ backgroundColor: 'red' }} onClick={declareTripleResult}>Declare Triple Winning</button>
                </div>

              )}

            </div>
          </div>
        </ModalComponent> : null
      }
      <div className="toppart-diff gamjsx">
        <div className="section--29485 gameJsx">
          <p className="nameDisplay-002">Kolkata Fatafat</p>
          <input type="date" name="date" id="dateGameWrrapper" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
          <button className="search-Btn-00239" onClick={handleSearch}>Search</button>
        </div>
        <div className="section-553872 gameJsx">
          <button className="generate-game" onClick={generateGame}>Generate Game</button>
        </div>
      </div>
      {gameState && gameState.length > 0 ? (<div className="baziWrapper">
        {
          gameState && gameState.map((item, key) => (
            <div className="baziChilds">
              <div className="baziDetails">
                <div className="baziName" style={{ marginBottom: '10px' }}>{item.gameName}</div>
                <div className="gameTime"><CiClock2 /> Game Time : {item.gameTimeStart} to {item.gameTimeEnd}</div>
                <div className="baziNumbers"><TfiCup />Results : {item.result} | {item.singleResult} | {item.id}</div>
              </div>
              <div className="baziActions">
                <div className="baziAction-Child">{item.gameStatus}</div>
                <div className="baziAction-Child">
                  <button onClick={() => changeStatus(setModalStatus, key, item.id)}>
                    Change Status
                  </button>

                </div>
                <div className="baziAction-Child">
                  <button
                    onClick={() => {
                      openFunction(setModalResult, key);
                      setCurrentResult(item.singleResult);
                      setCurrentTResult(item.result);
                      setGameID(`${item.id}`);
                      console.log(`${gameID}`);
                      fetchBet(item.id);
                      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                    }}
                  >
                    Make Result
                  </button>

                </div>
                <div className="baziAction-Child">
                  <button onClick={() => deleteBazi(item.id)}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        }


      </div>) : (<div> NO DATA</div>)}

    </div >
  );
};

export default Game;
