import React, { useEffect, useState } from 'react'
import { auth } from '../utils/firebase';
import './route.css'


const Home = () => {
  const [displayname, setname] = useState(null)

  useEffect(
    () => {
      setname(auth.currentUser.displayName)
      console.log(auth.currentUser.displayName);
    }
    , [displayname, auth])

  return (
    <div className='homePage'>
      Welcome Back, {displayname}
    </div>
  )
}

export default Home
