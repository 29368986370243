import React, { useEffect, useState } from 'react';
import UserList from '../../Components/userList';
import ModalComponent from '../../Components/modal';
import { auth, db } from '../../utils/firebase';
import { getDocs, collection, query, where, addDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';

const UserPanelAdmin = () => {
    const [addUser, setAddUser] = useState(false);
    const [userArr, setUserArr] = useState([]);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [type, setType] = useState('User');
    const [percentage, setPercentage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [email, setEmail] = useState(null)
    const [masterEmail, setMasterEmail] = useState('')
    const [password, setPassword] = useState('')
    const [adminEmail, setAdminEmail] = useState('')

    const handleInputChange = (e, setState) => {
        setState(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch documents from the 'users' collection
                const q = query(collection(db, "userProfile"), where("adminEmail", "==", auth.currentUser.email))
                const usersSnapshot = await getDocs(q);
                const usersDataArray = usersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));


                // Merge the data from both collections
                const mergedDataArray = usersDataArray;

                // Filter user list based on search query
                const filteredUsers = mergedDataArray.filter(user =>
                    (user.displayName && user.displayName.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (user.emailId && user.emailId.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (user.phoneNumber && user.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()))
                );


                setUserArr(filteredUsers);
            } catch (error) {
                console.error("Error fetching documents: ", error);
            }
        };

        fetchData();
    }, [searchQuery]);


    const addUserFunction = async () => {
        try {
            // Check if username already exists
            const usernameQuery = query(collection(db, "userProfile"), where("emailId", "==", email));
            const usernameQuerySnapshot = await getDocs(usernameQuery);
            if (!usernameQuerySnapshot.empty) {
                // Username already exists, cancel the operation
                console.log("Username already exists. Operation cancelled.");
                return;
            }

            // Create the new user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Update the displayName in Firebase Authentication
            await updateProfile(user, { displayName: name });

            // Add the new user to Firestore with the correct uid and other details
            await addDoc(collection(db, "userProfile"), {
                uid: user.uid, // Use the created user's uid
                displayName: name,
                username: username, // Add the username to the Firestore document
                emailId: email,
                type: type, // Assuming you will set this field later
                percentage: 0,
                phoneNumber: phoneNumber,
                wallet: 0,
                accessCode: '',
                fundAddHistory: [],
                fundWithdrawHistory: [],
                betLog: [],
                winLog: [],
                masterEmail: masterEmail,

                adminEmail: adminEmail
            });
            console.log("User added successfully");

            // Sign out the current user
            await signOut(auth);

            // Clear input fields after adding user
            setName('');
            setUsername('');
            setPhoneNumber('');
            setType('');
            setPercentage('');
            setPassword('');

            // Close the modal
            setAddUser(false);

            // Refresh user list
            setSearchQuery('');
        } catch (error) {
            console.error("Error adding user: ", error);
        }
    };


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div>
            {addUser &&
                <ModalComponent closeModal={() => setAddUser(false)}>
                    <div className='addUserModal' style={{ flexDirection: 'column', display: "flex" }}>
                        <p className='addUserModalHeading'>Add User</p>
                        <div className="info-Inputs-user">
                            <label htmlFor="name">
                                Name
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Enter Name'
                                    value={name}
                                    onChange={(e) => handleInputChange(e, setName)}
                                />
                            </label>
                            <label htmlFor="username">
                                Email ID
                                <input
                                    type="email"
                                    id="email"
                                    name="emailid"
                                    placeholder='Enter Email ID'
                                    value={email}
                                    onChange={(e) => handleInputChange(e, setEmail)}
                                />
                            </label>
                            <label htmlFor="adminEmail">
                                Admin Email
                                <input
                                    type="text"
                                    id="adminEmail"
                                    name="adminEmail"
                                    placeholder='Enter Master Email'
                                    value={adminEmail}
                                    onChange={(e) => handleInputChange(e, setAdminEmail)}
                                />
                            </label>
                            <label htmlFor="username">
                                Master Email
                                <input
                                    type="text"
                                    id="masteremail"
                                    name="emailid"
                                    placeholder='Enter Master Email ID'
                                    value={masterEmail}
                                    onChange={(e) => handleInputChange(e, setMasterEmail)}
                                />
                            </label>
                            <label htmlFor="phoneNumber">
                                Phone Number
                                <input
                                    type="number"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder='Enter Phone Number'
                                    value={phoneNumber}
                                    onChange={(e) => handleInputChange(e, setPhoneNumber)}
                                />
                            </label>
                            <label htmlFor="type">
                                Type
                                <select
                                    id="type"
                                    name="type"
                                    value={type}
                                    onChange={(e) => handleInputChange(e, setType)}
                                >
                                    <option value="User">User</option>
                                    <option value="Master">Master</option>
                                </select>
                            </label>
                            <label htmlFor="percentage">
                                Percentage
                                <input
                                    type="text"
                                    id="percentage"
                                    name="percentage"
                                    placeholder='Enter Percentage'
                                    value={percentage}
                                    onChange={(e) => handleInputChange(e, setPercentage)}
                                />
                            </label>
                            <label htmlFor="password">
                                Password
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder='Enter Password'
                                    value={password}
                                    onChange={(e) => handleInputChange(e, setPassword)}
                                />
                            </label>
                            <button className='addUserSubmit' onClick={addUserFunction}>Add User</button>
                        </div>
                    </div>
                </ModalComponent>
            }
            <div className="toppart-diff">
                <div className="section--29485">
                    <input type="text" placeholder='Search by Name' onChange={handleSearchChange} />
                    <input type="text" placeholder='Search by Username' onChange={handleSearchChange} />
                    <input type="text" placeholder='Search by Phone Number' onChange={handleSearchChange} />
                </div>
                <div className="section-553872">
                    <button id='search-userPanel' onClick={() => setSearchQuery(searchQuery)}>Search</button>
                    <button id='addUser-usePanel' onClick={() => setAddUser(true)}>Add User</button>
                </div>
            </div>
            <div className="v-Heading-UserList">
                <p>User List</p>
            </div>
            <UserList users={userArr} />
        </div>
    )
};

export default UserPanelAdmin;
