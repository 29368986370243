import React from 'react';
import './modal.css';
import { IoIosCloseCircle } from 'react-icons/io';


const ModalComponent = ({ children, closeModal, longHeight }) => {
    const handleClose = () => {
        closeModal();
    };

    const handleContentClick = (event) => {
        event.stopPropagation();
    };

    return (
        <>
            <div className='modalContainer' onClick={handleClose}></div>
            <div className={`modalContent ${longHeight ? 'longHeight' : ''}`} onClick={handleContentClick}>
                <div className="closeModal-button" onClick={handleClose}>
                    <IoIosCloseCircle />
                </div>
                {children}
            </div>
        </>
    );
};

export default ModalComponent;
