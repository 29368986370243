import React from 'react'
import './loading.css'

const Loading = () => {
    return (
        <div className='loading'>
            <div class="loading-container">
                <div class="loading-text">
                    <span>P</span>
                    <span>R</span>
                    <span>O</span>
                    <span>T</span>
                    <span>E</span>
                    <span>C</span>
                    <span>T</span>
                    <span>E</span>
                    <span>D</span>

                </div>
            </div>
        </div>
    )
}

export default Loading
