import React, { useEffect, useState, useCallback } from 'react';
import { db } from './../utils/firebase';
import { getDocs, collection } from 'firebase/firestore';
import moment from 'moment';

const ProfitLoss = () => {
    const [profitLossData, setProfitLossData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Function to format the date to dd/mm/yyyy
    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const fetchUserEmails = useCallback(async () => {
        const userProfileCollectionRef = collection(db, 'userProfile');
        const userProfileQuerySnapshot = await getDocs(userProfileCollectionRef);

        const userProfileMap = {};

        userProfileQuerySnapshot.forEach((doc) => {
            const userData = doc.data();
            userProfileMap[userData.emailId] = userData.type;
        });

        return userProfileMap;
    }, []);

    const fetchBetsForUsers = useCallback(async (userProfileMap) => {
        const betsCollectionRef = collection(db, 'bets');
        const betsQuerySnapshot = await getDocs(betsCollectionRef);

        const emailMap = {};
        const adminBetsMap = {};
        const masterBetsMap = {};

        betsQuerySnapshot.forEach((doc) => {
            const betData = doc.data();
            const userEmail = betData.user;
            const masterEmail = betData.masterEmail;
            const adminEmail = betData.adminEmail;
            const amount = betData.amount;
            const number = betData.number;
            const status = betData.status;
            const date = moment(betData.date, 'DD/MM/YYYY');

            const start = moment(startDate, 'DD/MM/YYYY');
            const end = moment(endDate, 'DD/MM/YYYY');

            if (date.isBetween(start, end, undefined, '[]')) {
                if (userEmail) {
                    const userType = userProfileMap[userEmail];
                    const key = `${userEmail}_${userType}`;

                    if (!emailMap[key]) {
                        emailMap[key] = {
                            email: userEmail,
                            type: userType,
                            amountPlayed: 0,
                            amountWon: 0,
                            profitLoss: 0,
                        };
                    }

                    let amountWon = 0;
                    if (status === 'win') {
                        if (number.toString().length === 1) {
                            amountWon = amount * 9;
                        } else if (number.toString().length === 3) {
                            amountWon = amount * 100;
                        }
                    }

                    emailMap[key].amountPlayed += amount;
                    emailMap[key].amountWon += amountWon;

                    // Update master and admin maps
                    if (masterEmail) {
                        if (!masterBetsMap[masterEmail]) {
                            masterBetsMap[masterEmail] = {
                                email: masterEmail,
                                type: 'Master',
                                amountPlayed: 0,
                                amountWon: 0,
                                profitLoss: 0,
                            };
                        }
                        masterBetsMap[masterEmail].amountPlayed += amount;
                        masterBetsMap[masterEmail].amountWon += amountWon;
                    }

                    if (adminEmail) {
                        if (!adminBetsMap[adminEmail]) {
                            adminBetsMap[adminEmail] = {
                                email: adminEmail,
                                type: 'Admin',
                                amountPlayed: 0,
                                amountWon: 0,
                                profitLoss: 0,
                            };
                        }
                        adminBetsMap[adminEmail].amountPlayed += amount;
                        adminBetsMap[adminEmail].amountWon += amountWon;
                    }
                }
            }
        });

        Object.keys(emailMap).forEach((key) => {
            emailMap[key].profitLoss = emailMap[key].amountPlayed - emailMap[key].amountWon;
        });

        Object.keys(masterBetsMap).forEach((key) => {
            masterBetsMap[key].profitLoss = masterBetsMap[key].amountPlayed - masterBetsMap[key].amountWon;
        });

        Object.keys(adminBetsMap).forEach((key) => {
            adminBetsMap[key].profitLoss = adminBetsMap[key].amountPlayed - adminBetsMap[key].amountWon;
        });

        const formattedData = [
            ...Object.values(emailMap),
            ...Object.values(masterBetsMap),
            ...Object.values(adminBetsMap),
        ];

        setProfitLossData(formattedData);
    }, [startDate, endDate]);

    useEffect(() => {
        const loadData = async () => {
            const userProfileMap = await fetchUserEmails();
            await fetchBetsForUsers(userProfileMap);
        };

        loadData();
    }, [fetchUserEmails, fetchBetsForUsers]);

    const handleSearch = () => {
        if (startDate && endDate) {
            setProfitLossData([]);
            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
        }
    };

    return (
        <div>
            <div className="toppart-diff">
                <div className="section--294851">
                    <label htmlFor="start-date">
                        <p>From Date</p>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </label>
                    <label htmlFor="end-date">
                        <p>To Date</p>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </label>
                </div>
                <div className="section-5538721">
                    <button
                        className="submitSearchQueryProfitLoss"
                        onClick={handleSearch}
                    >
                        Search by Date
                    </button>
                </div>
            </div>
            <div className="">
                <div className="table-wrapper">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>Email ID</th>
                                <th>Type</th>
                                <th>Total Played</th>
                                <th>Total Won</th>
                                <th>P/L</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profitLossData.map((entry, index) => (
                                <tr key={index}>
                                    <td>{entry.email}</td>
                                    <td>{entry.type}</td>
                                    <td>{entry.amountPlayed}</td>
                                    <td>{entry.amountWon}</td>
                                    <td>{entry.profitLoss}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ProfitLoss;
