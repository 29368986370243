import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../utils/firebase';

const BetHistoryMaster = () => {
    const [betHistory, setBetHistory] = useState([]);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [emailFilter, setEmailFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [todayDate, setTodayDate] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            const today = new Date();
            const formattedToday = formatDate(today);
            setTodayDate(formattedToday); // Set today's date in state
            fetchBetHistory(formattedToday);
        }
    }, [emailFilter, dateFilter]);

    const fetchBetHistory = async (todayDate) => {
        setLoading(true);
        try {
            let q = collection(db, 'bets');
            const filters = [];

            if (emailFilter) {
                filters.push(
                    where('user', '>=', emailFilter.toLowerCase()),
                    where('user', '<=', emailFilter.toLowerCase() + '\uf8ff')
                );
            }

            if (dateFilter) {
                const formattedDateFilter = formatDate(dateFilter);
                filters.push(where('date', '==', formattedDateFilter));
            } else {
                // Filter by today's date if dateFilter is not specified
                filters.push(where('date', '==', todayDate));
            }

            if (filters.length > 0) {
                q = query(q, ...filters);
            }

            const querySnapshot = await getDocs(q);
            const betHistoryData = [];

            const promises = querySnapshot.docs.map(async (betDoc) => {
                const betData = betDoc.data();

                // Check if betData.user is defined
                if (betData.user) {
                    const userQuery = query(
                        collection(db, 'userProfile'),
                        where('emailId', '==', betData.user),
                        where('masterEmail', '==', auth.currentUser.email)
                    );

                    const userSnapshot = await getDocs(userQuery);

                    userSnapshot.forEach(userDoc => {
                        betHistoryData.push({
                            id: betDoc.id,
                            date: betData.date,
                            time: betData.time,
                            number: betData.number,
                            amount: betData.amount,
                            email: betData.user,
                            uid: betData.uid,
                            gameName: betData.gameName
                        });
                    });
                }
            });

            await Promise.all(promises);
            // Sort bet history based on initial sorting criteria
            sortBetHistory(betHistoryData, sortBy, sortOrder);
        } catch (error) {
            console.error('Error fetching bet history:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };


    const handleCancelBet = async (betId, email, amount) => {
        const formatDateCancel = (date) => {
            const [day, month, year] = date.split('/');
            return `${day}-${month}-${year}`;
        };

        console.log(`Canceling bet with ID: ${betId}`);

        let gamedate;
        let gameid;
        let declaredBool;

        try {
            const docRef = doc(db, 'bets', betId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log('Bet document data:', docSnap.data());
                gamedate = formatDateCancel(docSnap.data().date);
                gameid = docSnap.data().gameID;
                console.log(gamedate, gameid);
            } else {
                console.log(`Bet ID ${betId} - No such document!`);
                return;
            }
        } catch (error) {
            console.error('Error fetching bet document:', error);
            return;
        }

        try {
            const declareData = doc(db, gamedate, gameid);
            const declareSnap = await getDoc(declareData);

            if (declareSnap.exists()) {
                console.log('Declare document data:', declareSnap.data());
                declaredBool = declareSnap.data().declared;
            } else {
                console.log(`Declare document for ${gamedate} / ${gameid} - No such document!`);
                return;
            }
        } catch (error) {
            console.error('Declare Check Failed due to:', error);
            return;
        }

        if (declaredBool) {
            console.log(`Bet cannot be canceled because it is already declared.`);
            return;
        }

        try {
            const userProfileQuery = query(collection(db, 'userProfile'), where('emailId', '==', email));
            const userProfileSnapshot = await getDocs(userProfileQuery);

            userProfileSnapshot.forEach(async (userProfileDoc) => {
                const userProfileData = userProfileDoc.data();
                console.log('User profile data:', userProfileData);
                const newWalletAmount = parseFloat(userProfileData.wallet) + parseFloat(amount);

                const userProfileRef = doc(db, 'userProfile', userProfileDoc.id);
                await updateDoc(userProfileRef, {
                    wallet: newWalletAmount
                });
            });

            await deleteDoc(doc(db, 'bets', betId));
            console.log(`Bet with ID ${betId} has been successfully deleted.`);

            setBetHistory(prevBetHistory => prevBetHistory.filter(bet => bet.id !== betId));
        } catch (error) {
            console.error('Error canceling bet:', error);
        }
    };


    const handleSort = (sortByField) => {
        const newSortOrder = sortBy === sortByField && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(sortByField);
        setSortOrder(newSortOrder);

        sortBetHistory(betHistory, sortByField, newSortOrder);
    };

    const sortBetHistory = (history, sortByField, order) => {
        const sortedHistory = [...history].sort((a, b) => {
            if (sortByField === 'email') {
                return order === 'asc' ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
            } else if (sortByField === 'date') {
                return order === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
            }
            return 0;
        });

        setBetHistory(sortedHistory);
    };

    if (loading) {
        return <div>Loading Data...</div>;
    }

    return (
        <div>

            <div className="inputSort-bethistory">
                <input
                    type="text"
                    placeholder="Enter Email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                />
                <input
                    type="date"
                    placeholder="Select Date"
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                />
            </div>


            <div className="table-wrapper">
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('date')}>Date</th>
                            <th>Time</th>
                            <th>Number</th>
                            <th>Amount</th>
                            <th onClick={() => handleSort('email')}>Email ID</th>
                            <th>Game Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {betHistory.map(bet => (
                            <tr key={bet.id}>
                                <td>{bet.date}</td>
                                <td>{bet.time}</td>
                                <td>{bet.number}</td>
                                <td>{bet.amount}</td>
                                <td>{bet.email}</td>
                                <td>Bazi {bet.gameName}</td>
                                <td>
                                    <button onClick={() => handleCancelBet(bet.id, bet.email, bet.amount)}>Cancel Bet</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BetHistoryMaster;
