import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../utils/firebase';

const FundAddHistory = () => {
    const [history, setHistory] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "userProfile"));
                let allHistory = [];
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.fundAddHistory) {
                        const emailId = data.emailId;  // Assuming emailId is a field in the userProfile document
                        const userHistory = data.fundAddHistory.map(historyEntry => ({
                            ...historyEntry,
                            emailId
                        }));
                        allHistory = [...allHistory, ...userHistory];
                    }
                });
                setHistory(allHistory);
            } catch (error) {
                console.error('Error fetching history:', error);
            }
        };

        fetchHistory();
    }, []);

    const formatDate = (date) => {
        const parts = date.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    };

    const formatDateForInput = (date) => {
        const parts = date.split("/");
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    const handleFilter = (status = '') => {
        setFilterStatus(status);
        setSelectedDate('');
    };

    const handleDateChange = (e) => {
        const dateValue = e.target.value;
        setSelectedDate(dateValue ? formatDate(dateValue) : '');
    };

    const filteredHistory = selectedDate
        ? history.filter(entry => entry.date === selectedDate)
        : history.filter(entry => filterStatus ? entry.status === filterStatus : true);

    return (
        <div className="history-container">
            <h1>Fund Addition History</h1>
            <div className="sort-filter-options">
                <div className="filter-options">
                    <input
                        type="date"
                        value={selectedDate ? formatDateForInput(selectedDate) : ''}
                        onChange={handleDateChange}
                    />
                    <button onClick={() => handleFilter('approved')}>
                        Approved
                    </button>
                    <button onClick={() => handleFilter('declined')}>
                        Rejected
                    </button>
                    <button onClick={() => handleFilter()}>
                        Clear Filter
                    </button>
                </div>
            </div>
            <div className="table-wrapper">
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Amount</th>
                            <th>Email ID</th>
                            <th>Wallet Before</th>
                            <th>Wallet After</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHistory.map((entry, index) => (
                            <tr key={index} onClick={() => console.log(entry)}>
                                <td>{entry.date}</td>
                                <td>{entry.time}</td>
                                <td>{entry.amount}</td>
                                <td>{entry.emailId}</td>
                                <td>{entry.walletBefore}</td>
                                <td>{entry.walletAfter}</td>
                                <td>{entry.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FundAddHistory;
